import { useTheme } from "@emotion/react";
import { Typography, Button, Link, Box, Grow, Fade, useScrollTrigger, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { useState } from "react";
import GymPic from "../assets/gympic.jpg";
import Portrait from "../assets/new-pics/newportrait.png";
import TrainingPic1 from "../assets/new-pics/trainingpic1.png";

const NewHome = () => {
  //Using useState to track if the page has faded in or not
  const [hasFadeIn, setHasFadeIn] = useState(false);
  const theme = useTheme();

  const StyledBox = styled(Box)({
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {},
    minHeight: "100vh",
    minWidth: "100%",
  });

  const BackgroundImage = styled("div")({
    backgroundImage: `url(${GymPic})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left top",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    position: "relative",
  });

  const QuoteContainer = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingBottom: "35%",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "10%",
    },
  }));

  const PortraitPic = styled("img")(({ theme }) => ({
    alignItems: "center",
    width: "60%",
    margin: "15px auto",
    borderRadius: "80%",
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
  }));

  const TrainingPic = styled("img")(({ theme }) => ({
    alignItems: "center",
    width: "100%",
    padding: "15px",
    margin: "15px auto",
    borderRadius: "25px",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
      padding: "0",
    },
  }));

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0.5,
  });

  const handleFadeIn = () => {
    if (!hasFadeIn && trigger) {
      setHasFadeIn(true);
    }
  };

  return (
    // Landing Page
    <>
      <Box sx={{ display: "flex", flexDirection: "column", minWidth: "100%" }}>
        <BackgroundImage>
          <QuoteContainer>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontFamily="Open Sans"
              color="white"
              sx={{
                mb: "8px",

                [theme.breakpoints.up("lg")]: {
                  fontSize: "5rem",
                },
              }}
            >
              LN TRAINING
            </Typography>
            <Typography
              variant="h6"
              fontWeight="light"
              fontFamily="Kanit"
              fontStyle="italic"
              color="white"
              sx={{
                padding: "0 5px",
                [theme.breakpoints.up("lg")]: {
                  fontSize: "2rem",
                },
              }}
            >
              Personal Training | Sports Performance | Online Coaching & Programming
            </Typography>
            <Box sx={{ mt: "10px", p: "15px" }}>
              <Link href="/contact" sx={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "1.4rem",
                    fontFamily: "Kanit",
                    fontWeight: "500",
                    height: "50px",
                    width: "fit-content",
                    backgroundColor: "#950101",

                    "&:hover": {
                      backgroundColor: "#950101",
                      transform: "scale(1.1)",
                      transition: "all 0.1s ease-in-out",
                    },
                    [theme.breakpoints.up("lg")]: {
                      fontSize: "2rem",
                      width: "250px",
                    },
                  }}
                >
                  Sign UP Now
                </Button>
              </Link>
            </Box>
          </QuoteContainer>
        </BackgroundImage>
      </Box>

      {/* Portrait Section with Name, Description, and Button call to action. */}

      <Box>
        <StyledBox>
          {/* Preventing from page always fading in on scroll */}
          {hasFadeIn ? (
            <>
              <PortraitPic src={Portrait} alt="lukas" className="portrait" />
              <Typography
                variant="h4"
                fontWeight="bold"
                fontSize="1.5rem"
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                  margin: "8px auto",
                  [theme.breakpoints.up("lg")]: {
                    fontSize: "2rem",
                  },
                }}
              >
                Lukas Nouri
              </Typography>
              <Typography
                variant="h5"
                fontFamily="Kanit"
                fontWeight="500"
                fontStyle="italic"
                fontSize="1.1rem"
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                  margin: "0 auto",
                  [theme.breakpoints.up("lg")]: {
                    fontSize: "1.5rem",
                  },
                }}
              >
                Certified Strength and Conditioning Specialist
              </Typography>
              <Typography
                variant="h6"
                fontSize="1.1rem"
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                  padding: "10px",
                  margin: "0 12px",

                  [theme.breakpoints.up("lg")]: {
                    fontSize: "1.3rem",
                  },
                }}
              >
                "Health, performance, and longevity are not a matter of chance, it's a matter of choice"
              </Typography>
              <Link href="/contact" sx={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#950101",
                    width: "fit-content",
                    margin: "25px auto",
                    fontFamily: "Kanit",
                    fontSize: "1.1rem",
                    fontWeight: "500",

                    "&:hover": {
                      backgroundColor: "#950101",
                      transform: "scale(1.1)",
                      transition: "all 0.1s ease-in-out",
                    },
                    [theme.breakpoints.up("lg")]: {
                      mb: "50px",
                      fontSize: "1.2rem",
                    },
                  }}
                >
                  Limited Spots Apply Now !
                </Button>
              </Link>
            </>
          ) : (
            <>
              <Fade in={trigger} timeout={1500} onEntered={handleFadeIn}>
                <PortraitPic src={Portrait} alt="lukas" />
              </Fade>
              <Grow in={trigger} timeout={900} onEntered={handleFadeIn}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  fontSize="1.5rem"
                  sx={{
                    alignItems: "center",
                    textAlign: "center",
                    margin: "8px auto",
                    [theme.breakpoints.up("lg")]: {
                      fontSize: "2rem",
                    },
                  }}
                >
                  Lukas Nouri
                </Typography>
              </Grow>
              <Grow in={trigger} timeout={900} onEntered={handleFadeIn}>
                <Typography
                  variant="h5"
                  fontFamily="Kanit"
                  fontWeight="500"
                  fontStyle="italic"
                  fontSize="1.1rem"
                  sx={{
                    alignItems: "center",
                    textAlign: "center",
                    margin: "0 auto",
                    [theme.breakpoints.up("lg")]: {
                      fontSize: "1.5rem",
                    },
                  }}
                >
                  Certified Strength and Conditioning Specialist
                </Typography>
              </Grow>
              <Grow in={trigger} timeout={900} onEntered={handleFadeIn}>
                <Typography
                  variant="h6"
                  fontSize="1.1rem"
                  sx={{
                    alignItems: "center",
                    textAlign: "center",
                    padding: "10px",
                    margin: "0 12px",
                    [theme.breakpoints.up("lg")]: {
                      fontSize: "1.3rem",
                    },
                  }}
                >
                  "Health, performance, and longevity are not a matter of chance, it's a matter of choice"
                </Typography>
              </Grow>
              <Grow in={trigger} timeout={900} onEntered={handleFadeIn}>
                <Link href="/contact" sx={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#950101",
                      width: "fit-content",
                      margin: "25px auto",
                      fontFamily: "Kanit",
                      fontSize: "1.1rem",
                      fontWeight: "500",

                      "&:hover": {
                        backgroundColor: "#950101",
                        transform: "scale(1.1)",
                        transition: "all 0.1s ease-in-out",
                      },
                      [theme.breakpoints.up("lg")]: {
                        mb: "50px",
                        fontSize: "1.2rem",
                      },
                    }}
                  >
                    Limited Spots Apply Now !
                  </Button>
                </Link>
              </Grow>
            </>
          )}
        </StyledBox>
      </Box>
      {/* About Me Section */}
      <Box>
        <StyledBox
          sx={{
            backgroundColor: "#2A2A2A",
          }}
        >
          <TrainingPic src={TrainingPic1} />
          <Box
            sx={{
              width: "100%",
              px: 1,
              mb: "25px",
              [theme.breakpoints.up("lg")]: {
                maxWidth: "700px",
              },
              [theme.breakpoints.only("xl")]: {
                maxWidth: "1050px",
              },
            }}
          >
            <Paper elevation={5} sx={{ p: 2, margin: "0 8px" }}>
              <Typography variant="h4" fontWeight="500" fontFamily="Kanit" sx={{ textAlign: "center" }}>
                About Me
              </Typography>
              <Typography variant="body1" sx={{ mt: "8px", textIndent: "1.5em" }}>
                Hello! Im Lukas Nouri, a dedicated fitness professional with a lifelong passion for sports. My extensive
                background spans various disciplines, including basketball, baseball, football, volleyball, soccer,
                Brazilian jiu-jitsu, muay Thai, and swimming. Along the way, I’ve also overcome many personal injuries,
                some of which required lower back and hip surgeries. My passion for sports and experience with acute
                injuries, led me to learn more about fitness, while my passion for coaching made me want to share my
                knowledge with others.
              </Typography>
              <Typography variant="body1" sx={{ mt: "15px", textIndent: "1.5em" }}>
                I hold a degree in kinesiology from Cal State University, Northridge, and my journey includes valuable
                internships, such as serving as an athletic trainer at Los Angeles Pierce College and a physical therapy
                aide at Northridge Hospital. My diverse experiences led me to earn the prestigious CSCS certification
                and establish my own private personal training venture, LN Training. Since then, I have also worked as a
                senior strength and conditioning coach at Monarch Athletic Club.
              </Typography>
              <Typography variant="body1" sx={{ mt: "15px", textIndent: "1.5em" }}>
                Throughout the years, I've had the privilege of working with a broad spectrum of clients, ranging from
                teens engaged in sports-specific training to seniors undergoing post-surgical rehabilitation. My
                expertise extends to training elite Division-1 athletes and guiding individuals transitioning from
                sedentary to active lifestyles. Through LN Training, I aim to leverage my knowledge and diverse
                experiences to empower clients in achieving their health, nutrition, sports performance, and fitness
                objectives.
              </Typography>
            </Paper>
          </Box>
        </StyledBox>
      </Box>
    </>
  );
};

export default NewHome;
